import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PermissionGuard } from './core/auth/guards/permission.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'confirmation-success', loadChildren: () => import('app/modules/auth/confirmation-success/confirmation-success.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/pages/dashboard/project/project.routes')},
            {path: 'projects', loadChildren: () => import('app/modules/admin/pages/projects/projects.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_PROJECTS'}},
            {path: 'projects/read/:id', loadChildren: () => import('app/modules/admin/pages/projects/read-view/read-view.routes')},
            {path: 'projects/edit/:id', loadChildren: () => import('app/modules/admin/pages/projects/add-edit-view/add-edit-view.routes')},
            {path: 'projects/create', loadChildren: () => import('app/modules/admin/pages/projects/add-edit-view/add-edit-view.routes')},


            {path: 'domains', loadChildren: () => import('app/modules/admin/pages/domains/domains.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_DOMAINS'}},
            {path: 'domains/read/:id', loadChildren: () => import('app/modules/admin/pages/domains/read-view/read-view.routes')},
            {path: 'domains/edit/:id', loadChildren: () => import('app/modules/admin/pages/domains/add-edit-view-domain/add-edit-view-domain.routes')},
            {path: 'domains/create', loadChildren: () => import('app/modules/admin/pages/domains/add-edit-view-domain/add-edit-view-domain.routes')},
            {path: 'domains/add-whois/:id', loadChildren: () => import('app/modules/admin/pages/domains/add-whois/add-whois.routes')},


            {path: 'accounts', loadChildren: () => import('app/modules/admin/pages/accounts/accounts.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_ACCOUNTS'}},
            {path: 'accounts/read/:id', loadChildren: () => import('app/modules/admin/pages/accounts/read/read.routes')},
            {path: 'accounts/edit/:id', loadChildren: () => import('app/modules/admin/pages/accounts/add-edit-view/add-edit-view.routes')},
            {path: 'accounts/create', loadChildren: () => import('app/modules/admin/pages/accounts/add-edit-view/add-edit-view.routes')},

            {path: 'sensia', loadChildren: () => import('app/modules/admin/pages/sensia/sensia.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'MANAGE_SCHEMAS'}},

            {path: 'sensia-audit', loadChildren: () => import('app/modules/admin/pages/sensia-audit/sensia-audit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'DO_AUDITS'}},

            {path: 'add-edit-account', loadChildren: () => import('app/modules/admin/pages/accounts/add-edit-view/add-edit-view.routes')},
            {path: 'add-edit-domain', loadChildren: () => import('app/modules/admin/pages/domains/add-edit-view-domain/add-edit-view-domain.routes')},
            {path: 'read-domain', loadChildren: () => import('app/modules/admin/pages/domains/read-view/read-view.routes')},
            {path: 'add-whois', loadChildren: () => import('app/modules/admin/pages/domains/add-whois/add-whois.routes')},
            {path: 'users', loadChildren: () => import('app/modules/admin/pages/users/users.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'LIST_USERS'}},
            {path: 'users/read/:id', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes')},
            {path: 'users/edit/:id', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'EDIT_USERS' }},
            {path: 'users/create', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'EDIT_USERS' }},
            {path: 'users/me/read', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes')},
            {path: 'users/me/edit', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes')},
        ]
    }
];
