import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ProjectMockApi } from 'app/mock-api/dashboards/project/api';

export const mockApiServices = [
    NavigationMockApi,
    NotificationsMockApi,
    ProjectMockApi,
    SearchMockApi,
];
