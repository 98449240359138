import {
    HttpClient
} from '@angular/common/http';
import {
    inject,
    Injectable
} from '@angular/core';
import {
    UserService
} from 'app/core/user/user.service';
import {
    catchError,
    of,
    switchMap,
    throwError,
    take,
    filter,
    Observable,
    BehaviorSubject,
    tap,
    map

} from 'rxjs';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private readonly API_URL = environment.apiUrl;
    private isTokenRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _isLoggedIn = new BehaviorSubject<boolean>(this.isLogged());

    constructor(private router: Router) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken');
    }

        /**
     * Setter & getter for access token
     */
        set refreshToken(token: string) {
            localStorage.setItem('refreshToken', token);
        }
    
        get refreshToken(): string {
            return localStorage.getItem('refreshToken');
        }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/recovery-password`, {
            email: email
        })
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable < any > {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.API_URL}/users/login`, credentials).pipe(
            switchMap((response: any) => {

                this.accessToken = response.token;
                this.refreshToken = response.refreshToken;

                return this.getUserById(response.user_id).pipe(
                    map((userResponse: any) => {
                        const user = {
                            id: userResponse.id,
                            avatar: userResponse.img ? userResponse.img : 'NO_AVATAR',
                            name: userResponse.name,
                            email: userResponse.email,
                            permissions: userResponse.permissions,
                            roles: userResponse.roles
                        };
                        localStorage.setItem('loggedUser', btoa(JSON.stringify(user)));
                        this._authenticated = true;
                        this._userService.user = user;
                        this._isLoggedIn.next(true);
                        return { ...response, user };
                    })
                );
            })
        );
    }
    

    /**
     * Sign in using the access token
     */

    signInUsingToken(): Observable < any > {
        // Sign in using the token
        return this._httpClient.post(`${this.API_URL}/users/refresh-token`, {refresh_token: this.refreshToken}).pipe(
            catchError(() =>

                // Return false
                of (false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.

                if (response.token) {
                    this.accessToken = response.token;
                    this.refreshToken = response.refreshToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;
                this._isLoggedIn.next(true);

                // Store the user on the user service
                // this._userService.user = response.user;
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable < any > {
        // TODO IYAN
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        this._authenticated = false;
        this._isLoggedIn.next(false);
        this.router.navigate(['./sign-out']);

        // this._httpClient.get(`${this.API_URL}/users/logout`).subscribe(el => {
        //     localStorage.removeItem('accessToken');
        //     localStorage.removeItem('refreshToken');
        //     this._authenticated = false;
        // })
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        email: string;password: string;
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/signup`, user)
    }

    /**
     * Verify user
     *
     * @param user
     */
    verifyUser(user: {
        email: string;
        verification_code: string;
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/activate`, user)
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string
    }): Observable < any > {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Change password
     *
     * @param passwords
     */
    changePassword(passwords: {
        password: string;old_password: string
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/change-password`, passwords);
    }

    /**
     * Check the authentication status
     */
    check(): Observable < boolean > {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    getUserById(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/users/${id}`);
    }

    getAccounts(): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/accounts`);
    }

    callRefreshToken(): Observable<any> {
        if (this.isTokenRefreshing) {
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1)
            );
        }

        this.isTokenRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.doRefreshToken().pipe(
            tap((newToken) => {
                this.isTokenRefreshing = false;
                this.refreshTokenSubject.next(newToken);
            }),
            catchError((error) => {
                this.isTokenRefreshing = false;
                this.refreshTokenSubject.next(false);
                return of(false);
            })
        );
    }

    private doRefreshToken(): Observable<any> {

        // if (!AuthUtils.isTokenExpired( this.accessToken) ) {
        //     console.log('token no expirado:', this.accessToken.slice(-10));
        //     return of(false);
        // }

        return this._httpClient.post(`${this.API_URL}/users/refresh-token`, {
            refresh_token: this.refreshToken
        }).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                if (response.token) {
                    this.accessToken = response.token;
                    this.refreshToken = response.refreshToken;
                }
                this._authenticated = true;
                this._isLoggedIn.next(true);
                // Store user on user service (if applicable)
                // this._userService.user = response.user;

                return of(true);
            })
        );
    }

    public isRefreshing(): boolean {
        return this.isTokenRefreshing;
    }

    public getRefreshTokenSubject(): BehaviorSubject<any> {
        return this.refreshTokenSubject;
    }

    public isLogged(): boolean {
        return this._authenticated;
    }
    isLoggedIn(): Observable<boolean> {
        return this._isLoggedIn.asObservable().pipe(
            tap(isLogged => {
                // console.log('Is logged in:', isLogged);
            }),
            catchError(() => of(false))
        );
    }


}
