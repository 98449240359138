import { inject, Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { createDefaultNavigation, createCompactNavigation, createFuturisticNavigation, createHorizontalNavigation } from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';
import { AuthService } from 'app/core/auth/auth.service';
import { filter, take, tap, of, catchError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
    private _compactNavigation: FuseNavigationItem[];
    private _defaultNavigation: FuseNavigationItem[];
    private _futuristicNavigation: FuseNavigationItem[];
    private _horizontalNavigation: FuseNavigationItem[];
    private _authService = inject(AuthService);

    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Esperar a que el usuario esté logueado
        this._authService.isLoggedIn().pipe(
            filter(isLoggedIn => isLoggedIn === true),
            take(1),
            tap(() => {
                this.registerHandlers();
            }),
            catchError(() => of([]))
        ).subscribe();
    }

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        const user = atob(localStorage.getItem('loggedUser'));
        const userData = JSON.parse(user);

        this._defaultNavigation = createDefaultNavigation(userData);
        this._compactNavigation = createCompactNavigation(userData);
        this._futuristicNavigation = createFuturisticNavigation(userData);
        this._horizontalNavigation = createHorizontalNavigation(userData);

        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact: cloneDeep(this._compactNavigation),
                    default: cloneDeep(this._defaultNavigation),
                    futuristic: cloneDeep(this._futuristicNavigation),
                    horizontal: cloneDeep(this._horizontalNavigation),
                },
            ];
        });
    }
}
